<template>
  <v-container fluid>
    <v-row class="profile--card" :style="candidate.notified && 'opacity: 0.6;'">
      <v-col sm="6" class="profile--info">
        <v-row justify="center" align="center" class="candidate--manipulation">
          <v-col cols="10" class="profile--actions">
            <v-col class="pa-2 acceptDeclineBtns">
              <v-btn
                @click.prevent="
                  changeCandidateStatus({
                    id: candidate._id,
                    filter: 'accepted',
                  })
                "
                color="#60D89A"
                outlined
                rounded
                class="mr-2"
                >Accept</v-btn
              >
              <v-btn
                color="#FD9E9E"
                outlined
                rounded
                @click.prevent="
                  changeCandidateStatus({
                    id: candidate._id,
                    filter: 'declined',
                  })
                "
                >Decline</v-btn
              >
            </v-col>
            <v-col class="pa-2">
              <v-select
                class="status--selection--select"
                rounded
                dense
                height="40"
                :items="statuses"
                item-text="value"
                filled
                :label="`Status: ${
                  candidate.status.toLowerCase() === 'undecided'
                    ? 'new'
                    : candidate.status.toLowerCase()
                }`"
                @input="changeStatusSelect"
                hide-details
                single-line
              ></v-select>
            </v-col>
          </v-col>
          <v-col cols="2">
            <div>
              <card-actions
                :notified="candidate.notified"
                :id="candidate._id"
              />
            </div>
          </v-col>
        </v-row>

        <v-card height="505px" class="scroll candidate--info">
          <div class="submitted--date">
            <div>
              <p v-if="candidate.submittedAt === 1" class="text-right">
                Applied {{ candidate.submittedAt }} Day ago
              </p>
              <p v-if="candidate.submittedAt === 0" class="text-right">Today</p>
              <p v-if="candidate.submittedAt > 1" class="text-right">
                Applied {{ candidate.submittedAt }} Days ago
              </p>
            </div>
          </div>
          <div class="text-left">
            <div
              v-for="(question, index) in candidate.questions"
              :key="index"
              class="QAbox"
            >
              <div
                v-if="findCorrectAnswer(candidate.answers, question.questionId)"
              >
                <div
                  v-if="
                    findCorrectAnswer(candidate.answers, question.questionId)
                      .answerType === 'video' ||
                    findCorrectAnswer(candidate.answers, question.questionId)
                      .answerType === 'audio'
                  "
                  class="videoAnswer"
                >
                  <span class="question--title">{{
                    question.questionTitle
                  }}</span>
                  <video-ask
                    :mediaType="
                      findCorrectAnswer(candidate.answers, question.questionId)
                        .answerType
                    "
                    :mediaUrl="
                      findCorrectAnswer(candidate.answers, question.questionId)
                        .answerText
                    "
                    :question="question.questionTitle"
                    :allAnswers="candidate.answers"
                    :qId="
                      findCorrectAnswer(candidate.answers, question.questionId)
                        .questionId
                    "
                    :questionsTitles="
                      candidate.questions.map(
                        ({ questionTitle, questionId }) => {
                          return { questionTitle, questionId };
                        }
                      )
                    "
                  ></video-ask>
                </div>
                <div v-else>
                  <span v-if="index >= 1" class="question--title">{{
                    question.questionTitle
                  }}</span>
                  <br />
                  <span :class="index === 0 ? 'first-name' : 'answer--text'">
                    {{
                      findCorrectAnswer(candidate.answers, question.questionId)
                        .answerText || ""
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col sm="6" class="rightSide--candidate--info">
        <v-card>
          <v-card-title class="justify-space-between chat--area-title"
            >Candidate Comments & Notes
            <v-icon color="#CBD5DF" class="text-right"
              >mdi-comment</v-icon
            ></v-card-title
          >
          <v-card-text class="chatArea" ref="messageDisplay">
            <div
              v-for="(chatMsg, index) in chatMessages"
              :key="index"
              :class="`${oddOrEven(index) ? 'text-left ' : 'text-right '} ${
                chatMsg._id
              } ${checkIfincludesMailSent(
                chatMsg.message
              )} ${checkIfincludesMailSent(chatMsg.message)}`"
            >
              <div class="textParagraph">
                <v-icon
                  class="del-icon"
                  @click.prevent="
                    deleteChatMessage(candidate._id, chatMsg._id, index)
                  "
                  >mdi-delete-forever</v-icon
                >
                <span>
                  {{ chatMsg.message }}
                </span>
              </div>
              <span>{{ chatMsg.author }}</span>
            </div>
          </v-card-text>
          <v-text-field
            hint="At least 3 characters"
            outlined
            hide-details
            no-resize
            rows="2"
            :value="chatText"
            @change="chatText = $event"
            @keyup.enter.native="addChatMessage(candidate._id, $event)"
            append-icon="mdi-send"
            @click:append="(e) => addChatMessage(candidate._id, e)"
            ref="chatForm"
            v-model="chatText"
          ></v-text-field>
        </v-card>
        <div class="rating--and-status">
          <v-card>
            <v-card-title>Candidate Rating</v-card-title>
            <rating
              :candidateId="candidate._id"
              :candidateRating="candidate.rating || 0"
            />
          </v-card>
          <v-card>
            <v-card-title>Candidate status</v-card-title>
            <v-card-text>
              <v-select
                :items="statuses"
                item-text="value"
                @change="changeStatusSelect"
                ref="selectedEl"
                dense
                :label="`#${
                  candidate.status.toLowerCase() === 'undecided'
                    ? 'new'
                    : candidate.status.toLowerCase()
                }`"
              ></v-select>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Rating from "./Rating.vue";
import axios from "axios";
import CardActions from "./CardActions.vue";
import VideoAsk from "./ProfileCard/VideoAsk.vue";

export default {
  components: { Rating, CardActions, VideoAsk },
  name: "ProfileView",
  data() {
    return {
      chatText: "",
      chatMessages: [],
      statuses: [
        { value: "New" },
        { value: "Accepted" },
        { value: "Declined" },
        { value: "Interviewed" },
      ],
    };
  },
  props: {
    candidate: Object,
  },
  methods: {
    ...mapActions(["changeCandidateStatus", "setError"]),
    findCorrectAnswer(answers, questionID) {
      // Regular expression to check if string is a valid UUID
    // const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    const answer = answers.find((a) => a.questionId === questionID)
      // if (regexExp.test(answer.answerText)) {
      //   return answers.find((a) => a.questionId === answer.questionId)
      // }

      return answer;
    },
    checkIfincludesMailSent(str) {
      if (str.includes("Mail sent: ")) return "mailSentDesign";
      return "";
    },
    changeStatusSelect(value) {
      const val = () => {
        if (value == "New") return "Undecided";
        return value[0].toLowerCase() + value.substr(1).toLowerCase();
      };

      this.changeCandidateStatus({
        id: this.candidate._id,
        filter: val(),
      });
      this.$refs["selectedEl"].reset();
    },
    async deleteChatMessage(formdId, chatId, index) {
      try {
        axios.put(`/api/form/delMessage/${formdId}`, {
          chatId,
        });
        this.chatMessages.splice(index, 1);
      } catch (error) {
        this.setError(error);
      }
    },
    async addChatMessage(id, e) {
      try {
        console.log(this.$refs.chatForm.validate());
        if (this.$refs.chatForm.validate()) {
          await axios.post("/api/form/addMessage/" + id, {
            author:
              this.$store.state.user.fullName || this.$store.state.user.email,
            message: e.target.value ? e.target.value.trim() : this.chatText,
          });
          this.candidate.chat.push({
            author:
              this.$store.state.user.fullName || this.$store.state.user.email,
            message: e.target.value ? e.target.value.trim() : this.chatText,
          });
          this.chatText = "";
        } else {
          this.setError("Minimum text length is 3 letters");
        }
      } catch (error) {
        this.setError(error);
      }
    },
    oddOrEven(nr) {
      if (nr % 2 == 0) return true;
      return false;
    },
  },
  computed: {
    ...mapGetters(["ratingCounts"]),
  },
  mounted() {
    const messageDisplay = this.$refs.messageDisplay;
    if (messageDisplay) {
      messageDisplay.scrollTop = messageDisplay.scrollHeight;
    }
    this.chatMessages = this.candidate.chat;
  },
  updated() {
    const messageDisplay = this.$refs.messageDisplay;
    messageDisplay.scrollTop = messageDisplay.scrollHeight;
  },
};
</script>

<style lang="scss" scoped>
.profile--actions {
  display: flex;
}
@media screen and (max-width: 1450px) {
  .acceptDeclineBtns {
    display: flex;
    flex-direction: row;
  }
  .profile--actions {
    button {
      font-size: 12px;
    }
  }
}
.candidate--info,
.submitted--date {
  padding: 20px 30px !important;
}
.candidate--manipulation {
  .v-btn {
    box-shadow: rgb(149 157 165 / 10%) 0px 8px 24px !important;
  }
  .col {
    padding-top: 0;
  }
}
.status--selection--select {
  box-shadow: rgb(149 157 165 / 10%) 0px 8px 24px !important;
  height: 40px;
  overflow: hidden;
}
.answer--text {
  color: #666666;
}
.chat--area-title {
  font-weight: bold;
  font-size: 18px;
  color: #707070;
}
.question--title {
  font-weight: bold;
  color: #666666;
  font-size: 18px;
}
.mailSentNotification {
  padding-bottom: 10px;
  color: #624cdb;
  font-size: 20px;
}
.QAbox {
  padding-bottom: 5px;
  padding-left: 10px;
  span {
    font-size: 16px;
  }
}
.first-name {
  color: #614bd9;
  font-weight: 700;
  font-size: 24px !important;
  margin-bottom: 10px;
}
.btns--and--date {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.rightSide--candidate--info {
  padding: 30px 30px;
  .v-card {
    .v-card__title {
      padding-bottom: 5px;
      background: #f2f0f0;
      font-size: 16px;
      font-weight: 550;
    }
  }
}
.profile--card {
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  box-shadow: 0px 20px 80px #0000000f;
  .v-card {
    box-shadow: none;
    background-color: #feffff;
    border: 2px solid rgba(184, 197, 211, 0.1);
    box-shadow: none !important;
  }
  .profile--info {
    padding: 30px 50px;
  }
}
.rating--and-status {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  height: fit-content;
  align-items: stretch;
  .v-card {
    margin: 5px 0 5px 0;
    width: 100%;
  }
  .v-card__title {
    font-size: 18px;
    font-weight: bold;
    color: #707070;
    padding: 10px 20px;
  }
  .vue-star-rating,
  .v-card__text {
    padding: 10px 20px;
    text-align: start;
    justify-content: start;
  }
}
.scroll {
  overflow-y: scroll;
  position: relative;
  .submitted--date {
    position: absolute;
    right: 0;
    top: 30px;
    color: #707070;
    font-size: 16px;
  }
}
.chatArea {
  min-height: 280px;
  height: 280px;
  overflow-y: scroll;
  div {
    display: flex;
    flex-direction: column;
  }
  span {
    color: #cbd5df;
    font-size: 12px;
  }
  .textParagraph {
    margin-bottom: 0;
    padding: 10px;
    width: fit-content;
    position: relative;
    .del-icon {
      display: none;
      position: absolute;
      bottom: 0;
      right: -0.5rem;
      font-size: 18px;
      &:hover {
        cursor: pointer;
        color: #614bd9;
      }
    }
    &:hover {
      .del-icon {
        display: block;
      }
    }
  }
  .text-right {
    padding-bottom: 10px;
    align-items: end;
    .textParagraph {
      background: #f9f9f9;
      border-radius: 20px 20px 0px 20px;
      cursor: context-menu;
      span {
        color: #666666;
        font-size: 14px;
      }
      &:hover {
        background: #cbd5df;
      }
    }
  }
  .text-left {
    padding-bottom: 10px;
    .textParagraph {
      background: #f9f9f9;
      border-radius: 20px 20px 20px 0px;
      color: #666666;
      font-size: 16px;
      cursor: context-menu;
      span {
        color: #666666;
        font-size: 14px;
      }
      &:hover {
        background: #cbd5df;
      }
    }
  }
  .status--select {
    input {
      font-size: 12px;
    }
  }
}
.mailSentDesign {
  span {
    color: #624cdb !important;
    i {
      padding: 0 !important;
      color: #624cdb !important;
    }
  }
}
.videoAnswer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
</style>
