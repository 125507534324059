var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"profile--card",style:(_vm.candidate.notified && 'opacity: 0.6;')},[_c('v-col',{staticClass:"profile--info",attrs:{"sm":"6"}},[_c('v-row',{staticClass:"candidate--manipulation",attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"profile--actions",attrs:{"cols":"10"}},[_c('v-col',{staticClass:"pa-2 acceptDeclineBtns"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"#60D89A","outlined":"","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.changeCandidateStatus({
                  id: _vm.candidate._id,
                  filter: 'accepted',
                })}}},[_vm._v("Accept")]),_c('v-btn',{attrs:{"color":"#FD9E9E","outlined":"","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.changeCandidateStatus({
                  id: _vm.candidate._id,
                  filter: 'declined',
                })}}},[_vm._v("Decline")])],1),_c('v-col',{staticClass:"pa-2"},[_c('v-select',{staticClass:"status--selection--select",attrs:{"rounded":"","dense":"","height":"40","items":_vm.statuses,"item-text":"value","filled":"","label":`Status: ${
                _vm.candidate.status.toLowerCase() === 'undecided'
                  ? 'new'
                  : _vm.candidate.status.toLowerCase()
              }`,"hide-details":"","single-line":""},on:{"input":_vm.changeStatusSelect}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',[_c('card-actions',{attrs:{"notified":_vm.candidate.notified,"id":_vm.candidate._id}})],1)])],1),_c('v-card',{staticClass:"scroll candidate--info",attrs:{"height":"505px"}},[_c('div',{staticClass:"submitted--date"},[_c('div',[(_vm.candidate.submittedAt === 1)?_c('p',{staticClass:"text-right"},[_vm._v(" Applied "+_vm._s(_vm.candidate.submittedAt)+" Day ago ")]):_vm._e(),(_vm.candidate.submittedAt === 0)?_c('p',{staticClass:"text-right"},[_vm._v("Today")]):_vm._e(),(_vm.candidate.submittedAt > 1)?_c('p',{staticClass:"text-right"},[_vm._v(" Applied "+_vm._s(_vm.candidate.submittedAt)+" Days ago ")]):_vm._e()])]),_c('div',{staticClass:"text-left"},_vm._l((_vm.candidate.questions),function(question,index){return _c('div',{key:index,staticClass:"QAbox"},[(_vm.findCorrectAnswer(_vm.candidate.answers, question.questionId))?_c('div',[(
                  _vm.findCorrectAnswer(_vm.candidate.answers, question.questionId)
                    .answerType === 'video' ||
                  _vm.findCorrectAnswer(_vm.candidate.answers, question.questionId)
                    .answerType === 'audio'
                )?_c('div',{staticClass:"videoAnswer"},[_c('span',{staticClass:"question--title"},[_vm._v(_vm._s(question.questionTitle))]),_c('video-ask',{attrs:{"mediaType":_vm.findCorrectAnswer(_vm.candidate.answers, question.questionId)
                      .answerType,"mediaUrl":_vm.findCorrectAnswer(_vm.candidate.answers, question.questionId)
                      .answerText,"question":question.questionTitle,"allAnswers":_vm.candidate.answers,"qId":_vm.findCorrectAnswer(_vm.candidate.answers, question.questionId)
                      .questionId,"questionsTitles":_vm.candidate.questions.map(
                      ({ questionTitle, questionId }) => {
                        return { questionTitle, questionId };
                      }
                    )}})],1):_c('div',[(index >= 1)?_c('span',{staticClass:"question--title"},[_vm._v(_vm._s(question.questionTitle))]):_vm._e(),_c('br'),_c('span',{class:index === 0 ? 'first-name' : 'answer--text'},[_vm._v(" "+_vm._s(_vm.findCorrectAnswer(_vm.candidate.answers, question.questionId) .answerText || "")+" ")])])]):_vm._e()])}),0)])],1),_c('v-col',{staticClass:"rightSide--candidate--info",attrs:{"sm":"6"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-space-between chat--area-title"},[_vm._v("Candidate Comments & Notes "),_c('v-icon',{staticClass:"text-right",attrs:{"color":"#CBD5DF"}},[_vm._v("mdi-comment")])],1),_c('v-card-text',{ref:"messageDisplay",staticClass:"chatArea"},_vm._l((_vm.chatMessages),function(chatMsg,index){return _c('div',{key:index,class:`${_vm.oddOrEven(index) ? 'text-left ' : 'text-right '} ${
              chatMsg._id
            } ${_vm.checkIfincludesMailSent(
              chatMsg.message
            )} ${_vm.checkIfincludesMailSent(chatMsg.message)}`},[_c('div',{staticClass:"textParagraph"},[_c('v-icon',{staticClass:"del-icon",on:{"click":function($event){$event.preventDefault();return _vm.deleteChatMessage(_vm.candidate._id, chatMsg._id, index)}}},[_vm._v("mdi-delete-forever")]),_c('span',[_vm._v(" "+_vm._s(chatMsg.message)+" ")])],1),_c('span',[_vm._v(_vm._s(chatMsg.author))])])}),0),_c('v-text-field',{ref:"chatForm",attrs:{"hint":"At least 3 characters","outlined":"","hide-details":"","no-resize":"","rows":"2","value":_vm.chatText,"append-icon":"mdi-send"},on:{"change":function($event){_vm.chatText = $event},"click:append":(e) => _vm.addChatMessage(_vm.candidate._id, e)},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addChatMessage(_vm.candidate._id, $event)}},model:{value:(_vm.chatText),callback:function ($$v) {_vm.chatText=$$v},expression:"chatText"}})],1),_c('div',{staticClass:"rating--and-status"},[_c('v-card',[_c('v-card-title',[_vm._v("Candidate Rating")]),_c('rating',{attrs:{"candidateId":_vm.candidate._id,"candidateRating":_vm.candidate.rating || 0}})],1),_c('v-card',[_c('v-card-title',[_vm._v("Candidate status")]),_c('v-card-text',[_c('v-select',{ref:"selectedEl",attrs:{"items":_vm.statuses,"item-text":"value","dense":"","label":`#${
                _vm.candidate.status.toLowerCase() === 'undecided'
                  ? 'new'
                  : _vm.candidate.status.toLowerCase()
              }`},on:{"change":_vm.changeStatusSelect}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }