<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    overlay-color="white"
    overlay-opacity="0.8"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        color="red lighten-2"
        dark
        v-bind="attrs"
        v-on="on"
        class="openDialogVid"
      >
        <div class="textDiv">
          <p>{{ mediaType }} Answer</p>
        </div>

        <div class="iconDiv">
          <v-icon>
            {{
              mediaType === "audio" ? "mdi-volume-source" : "mdi-play-circle"
            }}
          </v-icon>
        </div>
      </div>
    </template>

    <div v-if="dialog">
      <v-card
        class="videoCont"
        @mouseover="showControls(true)"
        @mouseleave="showControls()"
        min-height="700px"
        ref="videoMediaRef"
        background-color="transparent"
      >
        <div class="calPagination">
          <div
            v-for="(story, i) in mediaStories"
            :key="i"
            :class="videos === i ? 'white' : 'seeThru'"
          ></div>
        </div>
        <div class="backgroundFixedBg">
          <video :src="getBackgroundUrl" class="answerVideoBg">
            Your browser does not support the video tag.
          </video>
        </div>
        <v-carousel
          v-model="videos"
          height="auto"
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(story, i) in mediaStories" :key="i">
            <div v-if="story.answerType === 'audio'">
              <video
                muted
                loop
                autoplay
                src="@/assets/audiobg.mp4"
                class="audioBg"
              >
                Your browser does not support the video tag.
              </video>
              <div class="audioControls">
                <audio controls>
                  <source
                    :src="story.answerText"
                    type="audio/mpeg"
                    class="audioPlay"
                  />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </div>
            <div v-if="story.answerType === 'video'">
              <video :src="story.answerText" class="answerVideo">
                Your browser does not support the video tag.
              </video>
            </div>

            <div class="overlayTitle" style="visibility: hidden">
              <h6>{{ findCorrectQuestionTitle(story.questionId) }}</h6>
            </div>
          </v-carousel-item>
        </v-carousel>
        <div class="overlayTitle">
          <h6>{{ getMediaTitle.questionTitle }}</h6>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "VideoAsk",
  data() {
    return {
      dialog: false,
      videos: 0,
      mediaTitle: "",
      mediaStories: [
        {
          answerType: this.mediaType,
          answerText: this.mediaUrl,
          questionId: this.qId,
        },
      ],
    };
  },
  props: {
    mediaUrl: String,
    mediaType: String,
    question: String,
    allAnswers: Array,
    qId: String,
    questionsTitles: Array,
  },
  methods: {
    findCorrectQuestionTitle(id) {
      const foundOne = this.questionsTitles.find(
        (que) => que.questionId === id
      );
      return foundOne.questionTitle;
    },
    showControls(controlsVisibility = false) {
      const elements = document.querySelectorAll(".answerVideo");
      document.querySelectorAll(".overlayTitle").forEach((overlay) => {
        overlay.style.cssText += `display: ${
          controlsVisibility ? "none" : "block"
        };`;
      });

      elements.forEach((el) => {
        el.controls = controlsVisibility;
      });
    },
  },
  computed: {
    getMediaTitle() {
      const title = this.questionsTitles.find(
        (que) => que.questionId === this.mediaStories[this.videos].questionId
      );
      return title;
    },
    getBackgroundUrl() {
      const backgroundUrl = this.mediaStories[this.videos].answerText;
      return backgroundUrl;
    },
  },
  created() {
    this.mediaStories.push(
      ...this.allAnswers.filter(
        ({ questionId, answerType }) =>
          questionId !== this.qId &&
          (answerType === "audio" || answerType === "video")
      )
    );
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  background-color: transparent !important;
  position: relative;
}
.backgroundFixedBg {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(10px);
    filter: blur(30px);
  }
}
video {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.videoCont {
  border-radius: 10px !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: auto;
  justify-content: center;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  background: transparent;
  .audioBg {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .answerVideoBg {
    position: absolute;
  }
}
@media only screen and (max-height: 1100px) {
  .audioBg {
    height: 700px !important;
  }
  .answerVideo {
    height: 700px !important;
  }
}
.audioCont {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  position: relative;
  audio {
    margin-bottom: 10px;
  }
  video {
    width: 100%;
    margin-bottom: 40px;
    height: auto;
  }
}
.openDialogVid {
  display: flex;
  align-items: center;
  &:hover {
    .iconDiv,
    .textDiv {
      background-color: #f3f3f3;
      border-radius: 10px;
    }
  }
  .iconDiv {
    background: #fff;
    box-shadow: inset 0px 3px 6px #0000000f, 0px 5px 15px #00000012;
    border-radius: 50px;
    margin-left: 12px;
    padding: 5px;
  }

  p {
    color: #5f4dd3;
    margin: 5px 0px 5px 0px;
    &:first-letter {
      text-transform: capitalize;
    }
  }
}
.v-dialog {
  box-shadow: none;
  border: none;
}
.overlayTitle {
  position: absolute;
  padding: 10px;
  bottom: 0;
  backdrop-filter: blur(10px);
  left: 0px;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  color: #fefefe;
  h6 {
    margin: 10px 0;
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
  }
}
.audioControls {
  position: absolute;
  top: 40px;
  backdrop-filter: blur(10px);
  left: 0px;
  width: 100%;

  display: flex;
  justify-content: center;
}
.calPagination {
  width: 100%;
  display: flex;
  grid-column-gap: 1em;
  grid-template-columns: repeat(auto-fit, 1fr);
  height: 0.5em;
  padding: 0 1em;
  top: 5px;
  z-index: 1;
  position: absolute;
  .white,
  .seeThru,
  .whiteAud {
    height: 5px;
    margin: 2px;
    border-radius: 5px;
  }
  .white {
    box-sizing: border-box;
    width: 100%;
    border: solid #fff 1px;
  }
  .seeThru {
    box-sizing: border-box;
    width: 100%;
    background: rgba(0, 0, 0, 0.25);
    border: solid transparent 1px;
  }
  .whiteAud {
    box-sizing: border-box;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>
